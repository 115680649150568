<template>
    <div class="myCommission">
        <van-loading type="spinner"
                     v-if="loading"
                     color="#F0D19E"
                     vertical>加载中...</van-loading>
        <div class="content">
            <div class="logo"></div>
            <div class="name">{{ nickName }}</div>
            <div class="price">
                <span class="tip">¥</span>
                <span class="tip1">{{ numFilter(price) }}</span>
            </div>
            <div class="btnWrap">
                <van-button class="btn"
                            round
                            :class="{'btnTip': price == 0}"
                            :disabled="price == 0"
                            @click="handleCommission">提现</van-button>
                <div v-if="certifyStatus == 6"
                     @click="handleAuthAddress">实名账户信息</div>
            </div>
            <ul class="tip_rule">
                <li>1、满2元可提现，单次提现最高金额10000元，结算时需按个税相关条例缴纳税费，提现手续费费率由6%变更为7.2%。</li>
                <li>2、有效订单产生15个自然日后进入“可提现”状态；每月的10号和15号推广人可以对可提现金额发起提现申请。通常10个工作日内打款。</li>
                <li>3、提现结算前需要完善实名认证，保证姓名、手机号、身份证号三要素一致，并提供本人准确的银行卡账号后进行佣金结算。</li>
                <li>4、打款由运营方西藏福禄网络科技有限公司负责，西藏福禄通过其合作方：武汉福穗网络科技有限公司作为支付主体向推广人在酷联盟申报的收款账号打款。</li>
            </ul>
        </div>
    </div>
</template>

<script>
import './index.less'
import {
    getToken,
    removeToken,
    urlParse,
    sessionGet,
    sessionSet,
    isAlipayOrWechat,
    splitEndJudgeToken,
    setToken,
    MGTVVIP_page_view
} from '@/utils/tokenUtils'
import { Toast } from 'vant'
import { GetUserRealName } from '@/service/withdraw'
import { GetUserAccountInfo } from '@/service/my'
export default {
    components: {},
    data() {
        return {
            price: 0, // 可提现金额
            nickName: '', // 盟主昵称
            mid: getToken('mid'),
            mgUuid: getToken('mgUuid'),
            loading: false,

            nickName: '', // 本人姓名
            idCard: '', // 本人身份证
            tel: '', // 本人手机号
            certifyStatus: 0 // 认证状态(0 未处理，1认证成功（暂不使用），2认证失败（暂不使用）  3 默签成功 4 福穗认证中 5 福穗认证失败 6 福穗认证成功)
        }
    },
    computed: {},
    created() {
        const urlData = urlParse(window.location.href)
        // console.log('urlData', urlData)
        // this.price = Number(urlData.price) || 0 // 可提现金额
        this.nickName = urlData.nickName || '' // 盟主昵称
    },
    mounted() {
        // setTimeout(() => {
        //     // 埋点，会员数据上报（页面PV）
        //     let token = localStorage.getItem('token')
        //     // console.log('token', token)
        //     let mgUuid = token ? this.mgUuid : ''
        //     MGTVVIP_page_view('金额提现', mgUuid)
        // }, 200)
        this.init()
    },
    methods: {
        numFilter(value) {
            // 截取当前数据到小数点后三位
            // console.log("value", value);
            if (!value) return 0.0
            const tempVal = parseFloat(value).toFixed(3)
            const realVal = tempVal.substring(0, tempVal.length - 1)
            return realVal
        },
        init() {
            GetUserRealName().then((res) => {
                const { code, data = {} } = res
                if (code == '0') {
                    this.name = (data && data.realName) || ''
                    this.idCard = (data && data.identityCode) || ''
                    this.tel = (data && data.phone) || ''
                    this.certifyStatus = (data && data.certifyStatus) || 0
                } else {
                    this.$toast(message)
                }
                this.loading = false
            })
            GetUserAccountInfo().then(res => {
                const { code, data } = res
                if (code == '0') {
                    this.price = data.balance || 0 // 可提现金额
                }
            })
        },
        // 点击 提现
        handleCommission() {
            // this.certifyStatus = 0 // 测试用
            if (this.certifyStatus == 6) {
                this.$router.push({
                    path: '/withdrawal'
                })
            } else {
                this.$router.push({
                    path: '/bindRealNameAccount',
                    query: {
                        redirect: '/myCommission',
                        modify: false
                    }
                })
            }
        },
        // 点击 实名账户信息
        handleAuthAddress() {
            this.$router.push({
                path: '/bindRealNameAccount',
                query: {
                    redirect: '/myCommission',
                    modify: true
                    // name: this.name,
                    // idCard: this.idCard,
                    // tel: this.tel
                }
            })
        }
    }
}
</script>

<style>
</style>
