import https from '@/request/index'
// 奖励明细/分佣订单列表
export const GetUserCommissionPageList = (params) => {
  return https.post('/api/UserCommisson/GetUserCommissionPageList', params)
}
// 分佣订单佣金金额汇总
export const GetUserCommissionAmountSummery = (params) => {
  return https.post('/api/UserCommisson/GetUserCommissionAmountSummery', params)
}
// 分佣订单订单数汇总
export const GetUserCommissionCountSummery = (params) => {
  return https.post('/api/UserCommisson/GetUserCommissionCountSummery', params)
}
// 活动奖励列表
export const GetUserActivityPageList = (params) => {
  return https.post('/api/UserCommisson/GetUserActivityPageList', params)
}
// 提现记录列表
export const GetUserCashApplyPageList = (params) => {
  return https.post('/api/UserCash/GetUserCashApplyPageList', params)
}
// 盟主实名认证申请
export const ApplyUserRealName = (params) => {
  return https.post('/api/UserCash/ApplyUserRealName', params)
}
// 认证状态信息
export const GetUserRealName = (params) => {
  return https.post('/api/UserCash/GetUserRealName', params)
}
// 获取用户实名信息（带*****）
export const GetUserRealNameWithEncrypt = (params) => {
  return https.post('/api/UserCash/GetUserRealNameWithEncrypt', params)
}
// 获盟主提现申请
export const UserApplyCash = (params) => {
  return https.post('/api/UserCash/UserApplyCash', params)
}
// 获取用户提现账户列表
export const GetUserBankNewInfos = (params) => {
  return https.post('/api/UserCash/GetUserBankNewInfos', params)
}
// 推客新增提现账户
export const AddUseCashAccountNo = (params) => {
  return https.post('/api/UserCash/AddUseCashAccountNo', params)
}
// 获取用户提现账户详情
export const GetUserBankNewInfo = (params) => {
  return https.post('/api/UserCash/GetUserBankNewInfo', params)
}
// 修改用户提现账户信息
export const UpdateUserBankInfo = (params) => {
  return https.post('/api/UserCash/UpdateUserBankInfo', params)
}
